import React, { useState, useEffect  } from 'react';
import { Console } from 'console';
import {useHistory, useLocation} from 'react-router-dom'
import { Layout, Menu } from 'antd';
import { BulbOutlined, PaperClipOutlined, SkinOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Page_Legal_Cookies from './Page_Legal_Cookies'
import Page_Legal_Terms from './Page_Legal_Terms'
import Page_Legal_Privacy from './Page_Legal_Privacy'
import { RouteDefines } from './routes';

const { Header, Content, Footer, Sider } = Layout;

const Page_Legal = () => {

  const history = useHistory();
  const location = useLocation();
  const [menuItemSelection, setMenuItemSelection] = useState('');

  useEffect(() => {
    setMenuSelection();
    
  }, [location]);

  function handleClick(info:any) {
    history.push(info.key);
    setMenuItemSelection (info.key);
  };

  const setMenuSelection = () => {
    var route = location.pathname;
    if (route === RouteDefines.ROUTE_LEGAL_TERMS ||
        route === RouteDefines.ROUTE_LEGAL_COOKIES ||
        route === RouteDefines.ROUTE_LEGAL_PRIVACY) {
          //console.log('setting selection to', route);
      setMenuItemSelection(route);
    }
    else {
      setMenuItemSelection('');
      //console.log('clearing location');
    }
  }


    return (
      <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo" />
        <Menu  mode="inline" selectedKeys={[menuItemSelection]} onClick={handleClick}  >
          <Menu.Item key={RouteDefines.ROUTE_LEGAL_TERMS} icon={<PaperClipOutlined />}>
            General conditions
          </Menu.Item>
          <Menu.Item key={RouteDefines.ROUTE_LEGAL_PRIVACY} icon={<InfoCircleOutlined />}>
            Privacy policy
          </Menu.Item>
          <Menu.Item key={RouteDefines.ROUTE_LEGAL_COOKIES} icon={<BulbOutlined />}>
            Cookiepolicy
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        {menuItemSelection == RouteDefines.ROUTE_LEGAL_TERMS && <Page_Legal_Terms/>}
        {menuItemSelection == RouteDefines.ROUTE_LEGAL_PRIVACY && <Page_Legal_Privacy/>}
        {menuItemSelection == RouteDefines.ROUTE_LEGAL_COOKIES && <Page_Legal_Cookies/>}
      </Layout>
    </Layout>
    );
}

export default Page_Legal;