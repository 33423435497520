import React, { useEffect  } from 'react';


const Page_Legal_Terms = () => {
    
    

    useEffect(() => {
        
      return () => {
        
        }
      }, []);


    return (
        <div>
            Cormaps.com can not be held accountable for any damage you suffer by using it's information. It is a best effort to display the data. Always check the source data before making decision based on the information on this site.
            <br></br><br></br><br></br>
            
        </div>
    );
}

export default Page_Legal_Terms;
