
 
export class RouteDefines {
    public static readonly ROUTE_LEGAL_TERMS:string = "/legal/terms";
    public static readonly ROUTE_LEGAL_COOKIES:string = "/legal/cookies";
    public static readonly ROUTE_LEGAL_PRIVACY:string = "/legal/privacy";
    public static readonly ROUTE_MAPS:string = "/maps";
    public static readonly ROUTE_CONTACT:string = "/contact";


    
 }
