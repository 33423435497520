import React, { useState, useEffect, useRef  } from 'react';
import  config  from './config';
import * as atlas from 'azure-maps-control';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {useFetchContaminationData} from './useFetchContaminationData'
import {useFetchMunicipalityGeoData} from './useFetchMunicipalityGeoData'

import { renderToString } from 'react-dom/server'
import MapPopup from './MapPopup'
import moment from 'moment'
import { sortAndDeduplicateDiagnostics } from 'typescript';

type BelgianMunicipalityMapProps = {
    Language: string
}

const BelgianMunicipalityMap = (props:BelgianMunicipalityMapProps) => {
    
    const [mapReady, setMapReady] = useState(false);
    const [map, setMap] = useState<atlas.Map|null>(null);
    const geojson = useFetchMunicipalityGeoData();
    const contaminationDataAccessor = useFetchContaminationData();

    let userZoom:boolean = false;
    let zoomedOutZoom:number|undefined = undefined;

    var defaultColor = '#FFEDA0';
    var colorScale = [
            10, '#FED976',
            20, '#FEB24C',
            50, '#FD8D3C',
            100, '#FC4E2A',
            200, '#E31A1C',
            500, '#BD0026',
            1000, '#800026'
        ];

    useEffect(() => {
        const InitAsync=async () => {
            let res = await axios.get(config.backEndApi + '/api/AzureMapsKey');
            console.info(res);
            InitMap(res.data as string);
            console.info('Map initialized');
        }

        InitAsync(); // no waiting withing useeffect, just fire it up
        
        return () => {
        }
    }, []); // one time startup effect

    useEffect(()=>{ //we hve all the data to start showing the geodata

        if (!mapReady || 
            !contaminationDataAccessor || 
            !map ||
            !geojson)
             return;
        //Create a popup but leave it closed so we can update it and display it later.
        let popup = new atlas.Popup({
            position: [0, 0], closeButton:false
        });

        //Create a data source and add it to the map.
        let datasource = new atlas.source.DataSource();
        map.sources.add(datasource);

        //https://docs.microsoft.com/en-us/azure/azure-maps/data-driven-style-expressions-web-sdk#color-expressions
       var steppedExp:atlas.Expression = [
            'step',
            ['get', 'd'],
            defaultColor,
            10, '#FED976',
            20, '#FEB24C',
            50, '#FD8D3C',
            100, '#FC4E2A',
            200, '#E31A1C',
            500, '#BD0026',
            1000, '#800026'
        ];

        //Create a layer to render the polygon data.
        var polygonLayer = new atlas.layer.PolygonLayer(datasource, "zones", {
            fillColor: steppedExp, fillOpacity:0.5
        });

        var lineLayer = new atlas.layer.LineLayer(datasource, 'lines', {
            strokeColor: 'black',
            strokeWidth: 0.5
        });

        map.layers.add([polygonLayer, lineLayer]);

        //Add a mouse move event to the polygon layer to show a popup with information.
        map.events.add('mousemove', polygonLayer, function (e) {
            if (e.shapes && e.shapes.length > 0) {
                var shape = e.shapes[0] as atlas.Shape;
                var properties = shape.getProperties();

                let municipality:string= properties.a;
                let population:number = properties.b;
                let municipalityId:number = properties.c;
                //let municipalityId:number = shape.getId() as number;
                let contaminations:number = contaminationDataAccessor.GetMunicipalityData(municipalityId).GetLast14DaysContaminationCount();
                
                //Update the content of the popup.
                popup.setOptions({
                    content: renderToString(<MapPopup municipality={municipality} population={population} contaminations={contaminations}/>),
                    position: e.position
                });

                //Open the popup.
                popup.open(map);
            }
        });

        //Add a mouse leave event to the polygon layer to hide the popup.
        map.events.add('mouseleave', polygonLayer, function (e) {
            popup.close();
        });

        var featureCollection = geojson as unknown as atlas.data.FeatureCollection
        for(let i = 0; i < featureCollection.features.length; i++) {
            //add a property with the contamintaioncount to determine the color            
            featureCollection.features[i].properties.d=contaminationDataAccessor.GetMunicipalityData(featureCollection.features[i].properties.c).GetLast14DaysContaminationCount();
        }

        datasource.add(featureCollection);
        //datasource.importDataFromUrl(config.backEndApi + '/api/DataSourceBeMunicipalities');
        removeStuffFromMap(); //we do it here to make sure the function has the updated map-state
        map.events.add('styledata', removeStuffFromMap);

    }, [contaminationDataAccessor, mapReady, map, geojson]);


      //todo check https://stackoverflow.com/questions/57565196/azure-maps-event-when-completed-loading-of-a-datasource
      // todo: show progress: https://github.com/Azure-Samples/AzureMapsCodeSamples/blob/master/AzureMapsCodeSamples/Demos/Large%20GeoJSON%20Files.html

    const InitMap = (subScriptionKey:string) =>
    {
    
        if (!atlas.isSupported(true)) {
            //The browser is not supported. 
            //TODO set some state so the legent and source are not displayed
            let mapDomElement = document.getElementById('myMap');
            if (mapDomElement !== null) {
                mapDomElement.innerHTML = '<div style="position:absolute;width:calc(100% - 40px);color:#000;background-color:#fff194;top:calc(50% - 12px);left:0;padding:20px;font-family:arial;">This browser is not supported. Azure Maps uses <a href="https://get.webgl.org/">WebGL</a> and some required components do not appear to be available or have known performance caveats in this browser.</div>';
                return;
            }
        }
            
        var boundingBox = [2.297, 49.4728, 6.5, 51.58 ]; //[West, South, East, North]
        let map = new atlas.Map('myMap', {
                language: props.Language,
                authOptions: {
                    authType: atlas.AuthenticationType.subscriptionKey,
                    subscriptionKey: subScriptionKey
                },
                showLogo:false,
                showFeedbackLink:false,
                renderWorldCopies:false,
                maxBounds: boundingBox,
                view: 'Auto',
                style:'grayscale_light'
            });
        
        setMap(map);


            /*map.events.add('wheel', function () {
                // for now: resizing => big view
                //userZoom = true;
            });*/

            map.events.add('zoomend', function () {
                /*let camera = map.getCamera();
                //console.info( camera.zoom);
                if (userZoom && 
                    zoomedOutZoom != undefined &&
                    camera.zoom != undefined &&
                    camera.zoom < zoomedOutZoom+0.02)
                {
                    userZoom = false;  
                }
                else
                {
                    userZoom = true;
                }
                console.info(`Zoomend; userzoom is now ${userZoom} (zoomedOutZoom: ${zoomedOutZoom}, zoom:${camera.zoom}) ` );
                //console.info( camera.maxZoom);
                // for now: resizing => big view
                //userZoom = true;
                */
            });
            
            map.events.add('resize', function () {
                //map was sized
                if (!userZoom) {
                    map.setCamera({maxBounds:boundingBox, zoom:1});
                    console.info( 'Zoom is now', map.getCamera().zoom);
                    zoomedOutZoom = map.getCamera().zoom;
                }
            });

           

            //******************** */
           //Wait until the map resources are ready.
        map.events.add('ready', function () {
            map.setCamera({maxBounds:boundingBox, zoom:1});
            console.info( 'Zoom is now (from ready)', map.getCamera().zoom);
            zoomedOutZoom = map.getCamera().zoom;
            setMapReady(true);

            //Create a popup but leave it closed so we can update it and display it later.
            /*let popup = new atlas.Popup({
                position: [0, 0], closeButton:false
            });

            //Create a data source and add it to the map.
            //datasource = new atlas.source.DataSource();
            map.sources.add(datasource);

            //https://docs.microsoft.com/en-us/azure/azure-maps/data-driven-style-expressions-web-sdk#color-expressions

            var steppedExp:atlas.Expression = [
                'step',
                ['get', 'Contaminations'],
                defaultColor,
                10, '#FED976',
                20, '#FEB24C',
                50, '#FD8D3C',
                100, '#FC4E2A',
                200, '#E31A1C',
                500, '#BD0026',
                1000, '#800026'
            ];

            //Create a layer to render the polygon data.
            var polygonLayer = new atlas.layer.PolygonLayer(datasource, "zones", {
                fillColor: steppedExp, fillOpacity:0.5
            });

            var lineLayer = new atlas.layer.LineLayer(datasource, 'lines', {
                strokeColor: 'black',
                strokeWidth: 0.5
           });

            map.layers.add([polygonLayer, lineLayer]);

            //Add a mouse move event to the polygon layer to show a popup with information.
            map.events.add('mousemove', polygonLayer, function (e) {
                if (e.shapes && e.shapes.length > 0) {
                    var shape = e.shapes[0] as atlas.Shape;
                    var properties = shape.getProperties();

                    let municipality:string= properties.MunicipalityNameNl;
                    let contaminations:number = properties.Contaminations;
                    let population:number = properties.Population;
                    let incidence:number =  Math.round(contaminations * 100000/population);
                    //Update the content of the popup.
                    popup.setOptions({
                        content: '<div style="padding:10px"><b>' + municipality + '</b><br/>Besmettingen in de laatste 14 dagen: <b>' + contaminations +'</b><br/>Aantal inwoners in januari 2020: <b>' + population +  '</b><br/> Incidence: <b>'+ `${incidence}` +'</b> (gevallen * 100.000 / aantal inwoners)</div>',
                        position: e.position
                    });

                    //Open the popup.
                    popup.open(map);
                }
            });

            //Add a mouse leave event to the polygon layer to hide the popup.
            map.events.add('mouseleave', polygonLayer, function (e) {
                popup.close();
            });

            datasource.importDataFromUrl(config.backEndApi + '/api/DataSourceBeMunicipalities');*/

            //Remove borders on initial load. 
            //removeStuffFromMap();
           
        }); //end map ready function
        
          //Remove borders when the map style changes.
          //map.events.add('styledata', removeStuffFromMap);
      }

       function removeStuffFromMap() {
            var mapAsAny:any = map;
            var layers = mapAsAny.map.getStyle().layers;
        
            for (var i = 0; i < layers.length; i++) {
                //console.log(layers[i]['source-layer']);
                switch (layers[i]['source-layer']) {
                    //case 'Other label':                    
                    case 'Country name':
                    case 'Country border':
                    case 'Disputed country border':
                    case 'State border': //State/province borders
                        mapAsAny.map.setLayoutProperty(layers[i].id, 'visibility', 'none');
                        break;
                }
            }
    }
 
    //copied from https://github.com/Azure-Samples/AzureMapsCodeSamples/blob/master/AzureMapsCodeSamples/Demos/Create%20a%20Choropleth%20Map.html
    const legenditems = []
    legenditems.push(<div key="-1"><i style={{"background":defaultColor}}></i> 0-{colorScale[0]}<br/></div>);
    
    for (var i = 0; i < colorScale.length; i += 2) {
        legenditems.push(<div key={i}><i style={{"background":colorScale[i + 1]}}></i> {colorScale[i]+(colorScale[i + 2]?`-${colorScale[i + 2]}`:'+')}<br/></div>);
    }

    return (
        <div id="MunicipalityWrapper" style={{width:'100%' }}>
            <link rel="stylesheet" type="text/css" href="https://atlas.microsoft.com/sdk/javascript/mapcontrol/2/atlas.min.css" />
            
            <div id="myMap"   style={{height:'0', margin:'0', width:'100%', paddingTop:'78.8%'}}></div>
            <div id="legend">Aantal besmettingen<br/><br/>
                {legenditems}
            </div>
            <div style={{fontSize:10, color:"lightgray"}}><a href="https://www.sciensano.be" target="_blank" className="bottomMapLinks">Bron: sciensano.be</a>  -   Last data update: {moment(contaminationDataAccessor?.GetUpdateTimeStamp()).format("LLL")} - <a href="https://www.cormaps.com" target="_blank" className="bottomMapLinks">Powered by Cormaps</a></div>            
        </div>
    );
}

export default BelgianMunicipalityMap;