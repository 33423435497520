import React, { useState, useEffect  } from 'react';
import { Console } from 'console';

interface IMapPopupProps {
  municipality:string;
  population:number;
  contaminations:number;
}

const MapPopup = (props:IMapPopupProps) => {
    const incidence:number = Math.round(props.contaminations * 100000/props.population);

    return (
      <div style={{padding:"10px"}}>
        <b>{props.municipality}</b><br/>
        Besmettingen in de laatste 14 dagen: <b>{props.contaminations}</b> <br/>
        Aantal inwoners in januari 2020: <b>{props.population}</b> <br/>
        Incidence: <b>{incidence}</b> (gevallen * 100.000 / aantal inwoners)<br/>
      </div>
    );
}

export default MapPopup;