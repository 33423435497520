import React, { useState, useEffect, useRef  } from 'react';
import  config  from './config';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";



export const useFetchMunicipalityGeoData = () =>
{
  const [geoJson, setGeoJson] = useState();

  useEffect(() => {
      const fetchData = async () => {

          cleanupOldVersion('BeMunicipalitiesV1');
          cleanupOldVersion('BeMunicipalitiesV2');
          cleanupOldVersion('BeMunicipalitiesV3');
          const storageKey = 'BeMunicipalitiesV4';
          
          var data = localStorage.getItem(storageKey);
          //console.info(data);
          if (data != null && data != undefined &&  data && data.length){
            setGeoJson(JSON.parse(data));
            console.info('Got muni geo data from cache');
            return;
          } 
        
        
        const result = await axios(config.backEndApi + '/api/DataSourceBeMunicipalities');
        setGeoJson(result.data);
        localStorage.setItem(storageKey,  JSON.stringify(result.data));
      };
   
      fetchData();
    
    return () => {}
  }, []); 


  const cleanupOldVersion = (oldversionKey:string) => {
    var data = localStorage.getItem(oldversionKey);
    if (data != null && data != undefined &&  data && data.length){
      localStorage.removeItem(oldversionKey);
    }

  }

  return geoJson;

}
