import React, { useState, useEffect, useRef  } from 'react';
import  config  from './config';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {IContaminationDataDto, ContaminationDataAccessor} from './ContaminationDataDto';

/*interface IUseFetchContaminationData {
  dataAccessor:ContaminationDataAccessor;
}*/

export const useFetchContaminationData = () =>
{
  const [dataAccessor, setDataAccessor] = useState<ContaminationDataAccessor|null>(null);

  useEffect(() => {
      //todo : use useFetchContaminationData
      const fetchData = async () => {
      const result = await axios(config.backEndApi + '/api/ContaminationData');
      setDataAccessor(new ContaminationDataAccessor(result.data as IContaminationDataDto));
        //setUpdateTimeStamp(contaminationDataAccessor.GetUpdateTimeStamp())
      };
   
      fetchData();
    
    return () => {}
  }, []); 

  return dataAccessor;

}
