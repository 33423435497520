
export interface IMunicipalityContaminationDataOnly {
    a:number;
    b:number;
    c:number[];
}

export interface IContaminationDataDto {
  a:Date;
  b:Date;
  c:Date;
  d: IMunicipalityContaminationDataOnly[];
}

export class ContaminationDataAccessor
{
  _contaminationDataDto:IContaminationDataDto;
  /**
   *
   */
  constructor(contaminationDataDto:IContaminationDataDto) {
    this._contaminationDataDto = contaminationDataDto; //let's hope this is reference assignment
  }

  public GetUpdateTimeStamp():Date {
    return this._contaminationDataDto.a;
  }

  public GetFromDate():Date {
    return this._contaminationDataDto.b;
  }
  public GetToDate():Date {
    return this._contaminationDataDto.c;
  }

  public GetMunicipalityData(id:number):MunicipalityContaminationDataOnlyAccessor {
    for (let i=0; i < this._contaminationDataDto.d.length; i++) {
      if(this._contaminationDataDto.d[i].a === id){
        return new MunicipalityContaminationDataOnlyAccessor(this._contaminationDataDto.d[i]);
      }
    }

    throw new Error(`Could not find municipality with id ${id} (${this._contaminationDataDto.d.length} items)`);
  }

  public GetMunicipalityArray():IMunicipalityContaminationDataOnly[] {
    return this._contaminationDataDto.d;
  }
}

class  MunicipalityContaminationDataOnlyAccessor
{
  _municipalityContaminationDataOnly:IMunicipalityContaminationDataOnly;
  constructor(municipalityContaminationDataOnly:IMunicipalityContaminationDataOnly) {
    this._municipalityContaminationDataOnly = municipalityContaminationDataOnly;
  }

  public GetMunicipalityId():number  {
    return this._municipalityContaminationDataOnly.a;
  }

  public GetLast14DaysContaminationCount():number  {
    return this._municipalityContaminationDataOnly.b;
  }

}

