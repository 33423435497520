import React, { useState, useEffect  } from 'react';
import BelgianMunicipalityMap from './BelgianMunicipalityMap';
import BelgianMunicipalityMapGoogle from './BelgianMunicipalityMapGoogle';


import { Console } from 'console';

type Page_MapProps = {
  cookiesConsentGiven: boolean,
  language:string
}


const Page_Maps = (props:Page_MapProps) => {
    useEffect(() => {
        
      return () => {
        
        }
      }, []);

    return (
        <div >
            {props.cookiesConsentGiven === false && <div>Map will be shown after cookie consent.</div>}
            {props.cookiesConsentGiven !== false && <BelgianMunicipalityMap Language={props.language} />}
        </div>
    );
}

export default Page_Maps;