import React, { useState, useEffect  } from 'react';
import {useHistory, useLocation} from 'react-router-dom'
import { Console } from 'console';

import { Menu} from 'antd';
import { MailOutlined, EnvironmentOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { RouteDefines } from './routes';

const MainMenu = () => {

    const history = useHistory();
    const location = useLocation();
    const [menuItemSelection, setMenuItemSelection] = useState('');

    useEffect(() => {
      console.log('location shizzle', location.pathname);
      var route = location.pathname;
      if (route.length===0 || route ==='/') {
        setMenuItemSelection(RouteDefines.ROUTE_MAPS);
      }
      else if (route === RouteDefines.ROUTE_CONTACT){
        setMenuItemSelection(route);
      } else {
        setMenuItemSelection('');
      }       
      
    }, [location]);

    function handleClick(info:any) {
      console.info('click received', info);
      if (info.key === 'maps') {
        history.push('');
      } else {
        history.push(info.key);
      }
      setMenuItemSelection (info.key);
    };     

    return (
      <div style={{ display: "flex" }}>
        <Menu onClick={handleClick} selectedKeys={[menuItemSelection]} mode="horizontal" style={{ marginLeft: "auto"  }}>
          <Menu.Item key={RouteDefines.ROUTE_MAPS} icon={<EnvironmentOutlined />} >
            Kaart
          </Menu.Item>
          <Menu.Item key={RouteDefines.ROUTE_CONTACT} icon={<MailOutlined />}>
            Contact
          </Menu.Item>
        </Menu>
      </div> 
    );
}

export default MainMenu;